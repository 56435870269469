import { useState } from "react";
import GameScreen from "./GameScreen";
import StartScreen from "./StartScreen";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

const MenthalArithmetic = ({ changeTitle }) => {
  const [calculationType, setCalculationType] = useState(0);
  const [difficulty, setDifficulty] = useState(0);
  const [gameRunning, setGameRunning] = useState(false);
  const [timeLimit, setTimeLimit] = useState(true);

  const calculationSelectionChanged = (event) => {
    const newType = event.target.value;
    setCalculationType(newType);
  };

  const difficultySelectionChanged = (event) => {
    const newDifficulty = event.target.value;
    setDifficulty(newDifficulty);
  };

  const startClicked = () => {
    setGameRunning(true);
  };

  const cancelClicked = () => {
    setGameRunning(false);
  };

  const handleTimeLimitChange = (event) => {
    setTimeLimit(event.target.checked);
  };

  changeTitle("Kopfrechnen");

  return (
    <>
      {gameRunning ? (
        <>
          <GameScreen
            calculationType={calculationType}
            difficulty={difficulty}
            cancelClicked={cancelClicked}
            timeLimit={timeLimit}
          />
        </>
      ) : (
        <>
          <StartScreen
            calculationType={calculationType}
            calculationSelectionChanged={calculationSelectionChanged}
            difficulty={difficulty}
            difficultySelectionChanged={difficultySelectionChanged}
            startClicked={startClicked}
            handleTimeLimitChange={handleTimeLimitChange}
            timeLimit={timeLimit}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth={true}
            component={Link}
            to={`/MentalArithmetic/PDF/${calculationType}/${difficulty}`}
          >
            PDF
          </Button>
        </>
      )}
    </>
  );
};

export default MenthalArithmetic;
