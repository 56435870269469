import { useState } from "react";
import NavigationBar from "./NavigationBar";
import NavigationDrawer from "./NavigationDrawer";

const navigationElements = [
  {
    title: "Start",
    link: "/",
  },
  // {
  //   title: "Brüche",
  //   link: "/Fractions",
  // },
  {
    title: "Gleichungen",
    link: "/Equations",
  },
  {
    title: "Kopfrechnen",
    link: "/MentalArithmetic",
  },
];

const Navigation = ({ version, title }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const toggleDrawerHandler = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <>
      <NavigationBar
        toggleDrawerHandler={toggleDrawerHandler}
        version={version}
        title={title}
      />
      <NavigationDrawer
        navigationElements={navigationElements}
        drawerVisible={drawerVisible}
        toggleDrawerHandler={toggleDrawerHandler}
      />
    </>
  );
};

export default Navigation;
