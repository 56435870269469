import { Container, CssBaseline, useMediaQuery } from "@material-ui/core/";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import MenthalArithmeticRouting from "components/MentalArithmetic/MenthalArithmeticRouting";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import packageJson from "../package.json";
import Equations from "./components/Equations/Equations";
import Fractions from "./components/Fractions/Fractions";
import Home from "./components/Home/Home";
import Navigation from "./components/Navigation/Navigation";

const App = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#5b8a49",
          },
        },
      }),
    [prefersDarkMode]
  );

  const [title /*, setTitle*/] = useState();

  const changeTitle = (newTitle) => {
    // setTitle(newTitle);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Navigation version={packageJson.version} title={title} />
        <CssBaseline />
        <Container fixed style={{ marginTop: 20 }}>
          <Switch>
            <Route path="/Equations">
              <Equations changeTitle={changeTitle} />
            </Route>
            <Route path="/Fractions">
              <Fractions changeTitle={changeTitle} />
            </Route>
            <Route path="/MentalArithmetic">
              <MenthalArithmeticRouting changeTitle={changeTitle} />
            </Route>
            <Route path="/">
              <Home changeTitle={changeTitle} />
            </Route>
          </Switch>
        </Container>
      </Router>
    </ThemeProvider>
  );
};

export default App;
