const type1 = [
  {
    variableNumbers: {
      count: 8,
      minValue: 2,
      maxValue: 40,
    },
    excercise: ([a, b, c, d, e, f, g, h]) => {
      return {
        excercise: `${a}x + ${b} - ${c}x + ${d} = ${e} + ${f}x - ${g} + ${h}x`,
        solution: (e - g - b - d) / (a - c - f - h),
        steps: [
          {
            line: `${a}x + ${b} - ${c}x + ${d} = ${e} + ${f}x - ${g} + ${h}x`,
            nextStep: ``,
          },
          {
            line: `${a - c}x + ${b + d} = ${e - g} + ${f + h}x`,
            nextStep: `- ${b + d}`,
          },
          {
            line: `${a - c}x = ${e - g - b - d} + ${f + h}x`,
            nextStep: `- ${f + h}x`,
          },
          {
            line: `${a - c - f - h}x = ${e - g - b - d}`,
            nextStep: `: ${a - c - f - h}`,
          },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 8,
      minValue: 2,
      maxValue: 100,
    },
    excercise: ([a, b, c, d, e, f, g, h]) => {
      return {
        excercise: `${a} - ${b}x + ${c} - ${d}x = ${e}x + ${f} + ${g}x - ${h}`,
        solution: (a + c - f + h) / (e + g + b + d),
        steps: [
          {
            line: `${a} - ${b}x + ${c} - ${d}x = ${e}x + ${f} + ${g}x - ${h}`,
            nextStep: ``,
          },
          {
            line: `${a + c} - ${b + d}x = ${e + g}x + ${f - h}`,
            nextStep: `+ ${b + d}x`,
          },
          {
            line: `${a + c} = ${e + g + b + d}x + ${f - h}`,
            nextStep: `- ${f - h}`,
          },
          {
            line: `${a + c - f + h} = ${e + g + b + d}x`,
            nextStep: `: ${e + g + b + d}x`,
          },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 5,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c, d, e]) => {
      return {
        excercise: `(${a}x + ${b}) + (${c}x - ${d}) = ${e}x`,
        solution: (b - d) / (e - a - c),
        steps: [
          {
            line: `(${a}x + ${b}) + (${c}x - ${d}) = ${e}x`,
            nextStep: ``,
          },
          {
            line: `${a}x + ${b} + ${c}x - ${d} = ${e}x`,
            nextStep: `- ${a}x / - ${c}x`,
          },
          {
            line: `${b - d} = ${e - a - c}x`,
            nextStep: `: ${e - a - c}`,
          },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 5,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c, d, e]) => {
      return {
        excercise: `(${a}x + ${b}) - (${c}x - ${d}) = ${e}x`,
        solution: (b + d) / (e - a + c),
        steps: [
          {
            line: `(${a}x + ${b}) - (${c}x - ${d}) = ${e}x`,
            nextStep: ``,
          },
          {
            line: `${a}x + ${b} - ${c}x + ${d} = ${e}x`,
            nextStep: `- ${a}x / + ${c}x`,
          },
          {
            line: `${b + d} = ${e - a + c}x`,
            nextStep: `: ${e - a + c}`,
          },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 5,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c, d, e]) => {
      return {
        excercise: `${e}x = (${a}x + ${b}) + (${c}x - ${d})`,
        solution: (b - d) / (e - a - c),
        steps: [
          {
            line: `${e}x = (${a}x + ${b}) + (${c}x - ${d})`,
            nextStep: ``,
          },
          {
            line: `${e}x = ${a}x + ${b} + ${c}x - ${d}`,
            nextStep: `- ${a}x / - ${c}x`,
          },
          {
            line: `${e - a - c}x = ${b - d}`,
            nextStep: `: ${e - a - c}`,
          },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 5,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c, d, e]) => {
      return {
        excercise: `${e}x = (${a}x + ${b}) - (${c}x - ${d})`,
        solution: (b + d) / (e - a + c),
        steps: [
          {
            line: `${e}x = (${a}x + ${b}) - (${c}x - ${d})`,
            nextStep: ``,
          },
          {
            line: `${e}x = ${a}x + ${b} - ${c}x + ${d}`,
            nextStep: `- ${a}x / + ${c}x`,
          },
          {
            line: `${e - a + c}x = ${b + d}`,
            nextStep: `: ${e - a + c}`,
          },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 6,
      minValue: 2,
      maxValue: 15,
    },
    excercise: ([a, b, c, d, e, f]) => {
      return {
        excercise: `${a}(${b}x + ${c}) = (${d}x - ${e})${f}`,
        solution: (a * c + e * f) / (d * f - a * b),
        steps: [
          {
            line: `${a}(${b}x + ${c}) = (${d}x - ${e})${f}`,
            nextStep: ``,
          },
          {
            line: `${a * b}x + ${a * c} = ${d * f}x - ${e * f}`,
            nextStep: `- ${a * b}x`,
          },
          {
            line: `${a * c} = ${d * f - a * b}x - ${e * f}`,
            nextStep: `+ ${e * f}`,
          },
          {
            line: `${a * c + e * f} = ${d * f - a * b}x`,
            nextStep: `: ${d * f - a * b}`,
          },
        ],
      };
    },
    variable: "x",
  },
];

export default type1;
