import { Grid, IconButton, Toolbar, Typography } from "@material-ui/core/";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import Logo from "images/logo_mathe.png";
// import LogoDark from "../images/logo_mathe_darkMode.png";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  image: {
    height: "60px",
    margin: "5px 20px 0 0",
  },
}));

const NavigationBar = ({ toggleDrawerHandler, version, title }) => {
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar>
        <div>
          <img src={Logo} alt="Logo" className={classes.image} />
        </div>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              Mathematik {title && `- ${title}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" className={classes.title}>
              v{version}
            </Typography>
          </Grid>
        </Grid>

        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawerHandler}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
