import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

const NavigationDrawer = ({
  navigationElements,
  drawerVisible,
  toggleDrawerHandler,
}) => {
  const classes = useStyles();

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawerHandler}
      onKeyDown={toggleDrawerHandler}
    >
      <List>
        {navigationElements.map((navigationElement, index) => {
          return (
            <ListItem
              button
              component={Link}
              to={navigationElement.link}
              key={index}
            >
              {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
              <ListItemText primary={navigationElement.title} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
  return (
    <Drawer anchor="right" open={drawerVisible} onClose={toggleDrawerHandler}>
      {list()}
    </Drawer>
  );
};

export default NavigationDrawer;
