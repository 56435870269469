import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PDFViewer } from "@react-pdf/renderer";
import mentalArithmetic from "lib/math/mentalArithmetic";
import { Link, useParams } from "react-router-dom";
import MentalArithmeticPDF from "./MentalArithmeticPDF";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },

  pdf: {
    width: "100%",
    height: 500,
    marginBottom: theme.spacing(2),
  },
}));

const PDF = () => {
  const countOfExcercises = 20;
  const { calculationType, difficulty } = useParams();
  const excercises = mentalArithmetic.calcExercises(
    countOfExcercises,
    calculationType,
    difficulty
  );
  const classes = useStyles();
  return (
    <>
      {/* <Card className={classes.root}>
        <CardContent>
         <PDFDownloadLink
            document={<MentalArithmeticPDF excercises={excercises} />}
            fileName="kopfrechnen.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download now!"
            }
          </PDFDownloadLink>
        </CardContent>
      </Card> */}
      <PDFViewer className={classes.pdf}>
        <MentalArithmeticPDF excercises={excercises} />
      </PDFViewer>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth={true}
        component={Link}
        to="/MentalArithmetic"
      >
        Zurück
      </Button>
    </>
  );
};

export default PDF;
