import math from "./mathLib";
import types from "lib/template/EquationTypes/EquationTypes";

class Equations {
  calcExcercise = (difficulty) => {
    const variants = types[difficulty];
    const selectedVariant = variants[math.random(0, variants.length)];
    let newExcercise;
    do {
      let numbers = [];

      for (let i = 0; i < selectedVariant.variableNumbers.count; i++) {
        numbers = [
          ...numbers,
          math.random(
            selectedVariant.variableNumbers.minValue || 1,
            selectedVariant.variableNumbers.maxValue || 50
          ),
        ];
      }

      newExcercise = {
        excercise: selectedVariant.excercise(numbers),
        variable: selectedVariant.variable,
      };
    } while (newExcercise.excercise.solution % 1 !== 0);

    return newExcercise;
  };
}

const equations = new Equations();
Object.freeze(equations);

export default equations;
