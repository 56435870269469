import type0 from "./Type0";
import type1 from "./Type1";
import type2 from "./Type2";

const types = {
  0: type0,
  1: type1,
  2: type2,
};

export default types;
