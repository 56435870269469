import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import MenthalArithmetic from "./MentalArithmetic";
import PDF from "./PDF";

const MenthalArithmeticRouting = ({ changeTitle }) => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/PDF/:calculationType/:difficulty`}>
        <PDF changeTitle={changeTitle} />
      </Route>
      <Route path={match.path}>
        <MenthalArithmetic changeTitle={changeTitle} />
      </Route>
    </Switch>
  );
};

export default MenthalArithmeticRouting;
