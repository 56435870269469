class MathLib {
  random = (min = 0, max = 50) => {
    return parseInt(Math.random() * (max - min)) + min;
  };

  ggT = (a, b) => {
    if (b === 0) return a;
    return this.ggT(b, a % b);
  };

  calcAnFraction = (typeOfCalculation) => {
    var calculation = {
      zaehler1: this.random(1),
      nenner1: this.random(1),
      zaehler2: this.random(1),
      nenner2: this.random(1),
      sign: "+",
    };

    const factors = {
      factor1: this.ggT(calculation.zaehler1, calculation.nenner1),
      factor2: this.ggT(calculation.zaehler2, calculation.nenner2),
    };

    calculation = {
      zaehler1: calculation.zaehler1 / factors.factor1,
      nenner1: calculation.nenner1 / factors.factor1,
      zaehler2: calculation.zaehler2 / factors.factor2,
      nenner2: calculation.nenner2 / factors.factor2,
      sign: "+",
    };

    switch (typeOfCalculation) {
      case 1:
        calculation.sign = "-";
        break;
      case 2:
        calculation.sign = "*";
        break;
      case 3:
        calculation.sign = "/";
        break;
      default:
        calculation.sign = "+";
    }

    return String.raw`{${calculation.zaehler1} \over ${calculation.nenner1}} ${calculation.sign} {${calculation.zaehler2} \over ${calculation.nenner2}}`;
  };
}

const math = new MathLib();
Object.freeze(math);

export default math;
