import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import PDFHeader from "components/PDFHeader/PDFHeader";
import React from "react";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  section: {
    margin: 10,
    padding: 10,
  },

  excercise: {
    flexDirection: "row",
    marginBottom: 10,
    fontSize: 12,
  },
  number: {
    width: 50,
  },
  first: {
    width: 50,
  },
  sign: {
    width: 25,
  },
  second: {
    width: 50,
  },
  equals: {
    width: 25,
  },
});

// Create Document Component
const MentalArithmeticPDF = ({ excercises }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PDFHeader title="Kopfrechnen" />
        <View style={styles.section}>
          {excercises.map((excercises, index) => (
            <View key={index} style={styles.excercise}>
              <Text style={styles.number}>{index + 1}.</Text>
              <Text style={styles.first}>{excercises.firstValue}</Text>
              <Text style={styles.sign}>{excercises.sign}</Text>
              <Text style={styles.second}>{excercises.secondValue}</Text>
              <Text style={styles.equals}>=</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default MentalArithmeticPDF;
