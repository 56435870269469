import {
  Typography,
  Input,
  Button,
  Grid,
  LinearProgress,
  Card,
  CardContent,
} from "@material-ui/core";
import { useRef, useState, useEffect } from "react";
import mentalArithmetic from "lib/math/mentalArithmetic";
import EvaluationScreen from "./EvaluationScreen";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },

  gridItem: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const GameScreen = ({
  calculationType,
  difficulty,
  cancelClicked,
  timeLimit,
}) => {
  const classes = useStyles();

  const countOfExcercises = 20;
  const [currentExcercise, setCurrentExcercise] = useState(0);
  const [end, setEnd] = useState(false);
  const [excercises] = useState(
    mentalArithmetic.calcExercises(
      countOfExcercises,
      calculationType,
      difficulty
    )
  );
  const [timeBarColor, setTimeBarColor] = useState("primary");
  let maxTimeInSeconds = 0;
  switch (parseInt(difficulty)) {
    case 0:
      maxTimeInSeconds = 300;
      break;
    case 1:
      maxTimeInSeconds = 480;
      break;
    case 2:
      maxTimeInSeconds = 540;
      break;
    case 3:
      maxTimeInSeconds = 600;
      break;
    default:
      maxTimeInSeconds = 0;
  }
  const [MIN, MAX] = [0, maxTimeInSeconds];
  const [timer, setTimer] = useState({
    running: timeLimit,
    timeLeft: 0,
    startTime: Date.now(),
    durationInSeconds: maxTimeInSeconds,
  });

  const answerInput = useRef(null);

  const saveAnswer = () => {
    excercises[currentExcercise].userSolution = parseInt(
      answerInput.current.value
    );
    answerInput.current.value = "";
    const newExcercise = currentExcercise + 1;
    if (newExcercise >= excercises.length) {
      stopTimer();
      // console.log("Dauer: " + (timer.durationInSeconds - timer.timeLeft));
      setEnd(true);
    } else setCurrentExcercise(newExcercise);

    answerInput.current.focus();
  };

  useEffect(() => {
    let timerTimeout;
    if (timer.running) {
      timerTimeout = setTimeout(() => {
        const timeElapsed = Math.floor((Date.now() - timer.startTime) / 1000);
        const timeLeft = timer.durationInSeconds - timeElapsed;
        const running = timeLeft > 0;

        if (timeLeft <= 0) {
          setEnd(true);
        }

        if (timeLeft <= 10) {
          setTimeBarColor("secondary");
        }
        setTimer({
          ...timer,
          running: running,
          timeLeft: timeLeft,
        });
      }, 500);
    }

    return () => clearTimeout(timerTimeout);
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") saveAnswer();
  };

  // const startTimer = () => {
  //   setTimer({ running: true, startTime: new Date() });
  // };

  const stopTimer = () => {
    setTimer({ running: false, startTime: timer.startTime });
  };

  const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

  return (
    <>
      {end ? (
        <EvaluationScreen
          excercises={excercises}
          cancelClicked={cancelClicked}
        />
      ) : (
        <>
          <LinearProgress
            variant="determinate"
            value={normalise(timer.timeLeft)}
            color={timeBarColor}
          />
          <Grid container direction="column">
            {excercises && (
              <>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item className={classes.gridItem}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          key="1"
                          align="center"
                        >
                          Aufgabe {currentExcercise + 1} von {excercises.length}
                        </Typography>

                        {excercises[currentExcercise] ? (
                          <Typography
                            variant="h3"
                            gutterBottom
                            key="1"
                            align="center"
                          >
                            {excercises[currentExcercise].firstValue}{" "}
                            {excercises[currentExcercise].sign}{" "}
                            {excercises[currentExcercise].secondValue}
                          </Typography>
                        ) : (
                          <Typography
                            variant="h3"
                            gutterBottom
                            key="1"
                            color="error"
                          >
                            Es ist ein interner Fehler aufgetreten. Bitte
                            versuchen Sie es erneut. (Error Code: E001)
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item style={{ width: "100%", marginBottom: "20px" }}>
                    <Input
                      inputRef={answerInput}
                      placeholder="Antwort"
                      type="number"
                      fullWidth={true}
                      autoFocus={true}
                      onKeyDown={handleKeyDown}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={saveAnswer}
                    fullWidth={true}
                    style={{ marginBottom: "20px" }}
                  >
                    Weiter
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth={true}
                    onClick={cancelClicked}
                  >
                    Abbrechen
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default GameScreen;
