import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import React from "react";
import Logo from "images/logo_mathe.png";

// Create styles
const styles = StyleSheet.create({
  container: {
    margin: 10,
    padding: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  image: {
    height: 80,
  },

  title: {
    fontSize: 24,
    fontWeight: "900",
  },
});

// Create Document Component
const PDFHeader = ({ title }) => {
  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.section}>
        <Image src={Logo} style={styles.image} />
      </View>
    </View>
  );
};

export default PDFHeader;
