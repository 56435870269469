const type0 = [
  {
    variableNumbers: {
      count: 3,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c]) => {
      return {
        excercise: `${a}x + ${b} = ${c}`,
        solution: (c - b) / a,
        steps: [
          { line: `${a}x + ${b} = ${c}`, nextStep: `- ${b}` },
          { line: `${a}x = ${c - b}`, nextStep: `: ${a}` },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 3,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c]) => {
      return {
        excercise: `${a}x - ${b} = ${c}`,
        solution: (c + b) / a,
        steps: [
          { line: `${a}x + ${b} = ${c}`, nextStep: `+ ${b}` },
          { line: `${a}x = ${c + b}`, nextStep: `: ${a}` },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 3,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c]) => {
      return {
        excercise: `${a} = ${b}x - ${c}`,
        solution: (a + c) / b,
        steps: [
          { line: `${a} = ${b}x - ${c}`, nextStep: `+ ${c}` },
          { line: `${a + c} = ${b}x`, nextStep: `: ${b}` },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 3,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c]) => {
      return {
        excercise: `${a} = ${b}x + ${c}`,
        solution: (a - c) / b,
        steps: [
          { line: `${a} = ${b}x + ${c}`, nextStep: `- ${c}` },
          { line: `${a - c} = ${b}x`, nextStep: `: ${b}` },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 4,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c, d]) => {
      return {
        excercise: `${a}x + ${b} = ${c}x + ${d}`,
        solution: (d - b) / (a - c),
        steps: [
          { line: `${a}x + ${b} = ${c}x + ${d}`, nextStep: `- ${b}` },
          { line: `${a}x = ${c}x + ${d - b}`, nextStep: `- ${c}x` },
          { line: `${a - c}x = ${d - b}`, nextStep: `: ${a - c}` },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 4,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c, d]) => {
      return {
        excercise: `${a}x - ${b} = ${c}x + ${d}`,
        solution: (d + b) / (a - c),
        steps: [
          { line: `${a}x - ${b} = ${c}x + ${d}`, nextStep: `+ ${b}` },
          { line: `${a}x = ${c}x + ${d + b}`, nextStep: `- ${c}x` },
          { line: `${a - c}x = ${d + b}`, nextStep: `: ${a - c}` },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 4,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c, d]) => {
      return {
        excercise: `${a}x - ${b} = ${c}x - ${d}`,
        solution: (b - d) / (a - c),
        steps: [
          { line: `${a}x - ${b} = ${c}x - ${d}`, nextStep: `+ ${b}` },
          { line: `${a}x = ${c}x + ${b - d}`, nextStep: `- ${c}x` },
          { line: `${a - c}x = ${b - d}`, nextStep: `: ${a - c}` },
        ],
      };
    },
    variable: "x",
  },

  {
    variableNumbers: {
      count: 4,
      minValue: 2,
      maxValue: 50,
    },
    excercise: ([a, b, c, d]) => {
      return {
        excercise: `${a}x - ${b} = ${d} - ${c}x`,
        solution: (d + b) / (a + c),
        steps: [
          { line: `${a}x - ${b} = ${d} - ${c}x`, nextStep: `+ ${b}` },
          { line: `${a}x = -${c}x + ${d + b}`, nextStep: `+ ${c}x` },
          { line: `${a + c}x = ${d + b}`, nextStep: `: ${a + c}` },
        ],
      };
    },
    variable: "x",
  },
];

export default type0;
