import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
} from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

const EvaluationScreen = ({ excercises, cancelClicked }) => {
  var errorQuote;
  const evaluation = () => {
    var countFalse = 0;
    excercises.forEach((excercise) => {
      if (excercise.solution !== excercise.userSolution) countFalse++;
    });
    errorQuote = Math.round((countFalse / excercises.length) * 100);
  };

  evaluation();

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Deine Fehlerquote beträgt {errorQuote} %
      </Typography>
      <Typography variant="body1" gutterBottom>
        {errorQuote === 0
          ? "Hervorragende Leistung! Du hast keinen Fehler gemacht"
          : errorQuote <= 10
          ? "Gute Leistung"
          : errorQuote <= 30
          ? "Deine Leistung war Ok. Übe fleißig weiter, um noch besser zu werden."
          : "Deine Leistung war nicht gut. Übe fleißig weiter, um noch besser zu werden."}
      </Typography>
      <List component="nav" aria-label="evaluation">
        {excercises.map((excercise, i) => {
          const excerciseCorrect =
            excercise.solution === excercise.userSolution;
          const calculation =
            excercise.firstValue +
            " " +
            excercise.sign +
            " " +
            excercise.secondValue +
            " = " +
            (excercise.userSolution || "Keine Antwort") +
            (!excerciseCorrect
              ? " (Richtige Antwort = " + excercise.solution + ")"
              : "");
          return (
            <ListItem key={i}>
              <ListItemText primary={calculation} />
              <ListItemIcon>
                {excerciseCorrect ? (
                  <CheckCircleRoundedIcon style={{ color: "#0f0" }} />
                ) : (
                  <CancelRoundedIcon style={{ color: "#f00" }} />
                )}
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>

      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth={true}
        onClick={cancelClicked}
      >
        Zurück zur Startseite
      </Button>
    </>
  );
};

export default EvaluationScreen;
