import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  Typography,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { MathComponent } from "mathjax-react";
import { useRef, useState } from "react";
import mathEquations from "lib/math/equations";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },
  gridSolutionSteps: {
    width: 400,
  },
  btn: {
    marginBottom: theme.spacing(2),
  },
}));

const errorMessage = "Deine Antwort war falsch. Probier es nochmal.";
const successMessage = "Deine Antwort ist richtig";

const Equations = ({ changeTitle }) => {
  const classes = useStyles();
  changeTitle("Gleichungen");

  const [difficulty, setDifficulty] = useState(0);
  const [calculation, setCalculation] = useState(
    mathEquations.calcExcercise(difficulty)
  );

  const [tryedToSolve, setTryedToSolve] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const [buttonText, setButtonText] = useState("Prüfen");

  const [snackbar, setSnackbar] = useState({
    open: false,
    type: "error",
    text: errorMessage,
  });

  const solutionInput = useRef(null);

  const handleButtonClick = () => {
    if (buttonText === "Nächste Aufgabe") {
      setButtonText("Prüfen");
      solutionInput.current.value = "";
      solutionInput.current.focus();
      setTryedToSolve(false);
      setShowSolution(false);
      setCalculation(mathEquations.calcExcercise(difficulty));
    } else if (
      parseInt(solutionInput.current.value) === calculation.excercise.solution
    ) {
      setSnackbar({ open: true, type: "success", text: successMessage });
      setButtonText("Nächste Aufgabe");
    } else {
      setTryedToSolve(true);
      setSnackbar({ open: true, type: "error", text: errorMessage });
    }
  };

  const handleButtonClickShowSolution = () => {
    setShowSolution(true);
    setButtonText("Nächste Aufgabe");
    setTryedToSolve(false);
  };

  const calculationSelectionChanged = (event) => {
    const newType = event.target.value;
    setDifficulty(newType);
    setCalculation(mathEquations.calcExcercise(newType));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    console.log("close");

    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Schwierigkeitsgrad auswählen
          </Typography>
          <RadioGroup
            aria-label="Rechneart"
            value={`${difficulty}`}
            onChange={calculationSelectionChanged}
            row={true}
          >
            <FormControlLabel value="0" control={<Radio />} label="1" />
            <FormControlLabel value="1" control={<Radio />} label="2" />
          </RadioGroup>
        </CardContent>
      </Card>

      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h4" gutterBottom align="center">
            Aufgabe
          </Typography>
          <MathComponent tex={calculation.excercise.excercise} />
        </CardContent>
      </Card>

      <Card className={classes.root}>
        <CardContent>
          <Grid container className={classes.root} justify="center">
            <Grid item>
              <Input placeholder="Antwort" inputRef={solutionInput} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {showSolution ? (
        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h4" gutterBottom align="center">
              Lösungsweg
            </Typography>

            <Grid container direction="column" align="center">
              {calculation.excercise.steps.map((step, i) => {
                return (
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    className={classes.gridSolutionSteps}
                    key={i}
                  >
                    <Grid item>
                      <MathComponent tex={`${step.line}`} />
                    </Grid>
                    <Grid item>
                      <MathComponent tex={`| ${step.nextStep}`} />
                    </Grid>
                  </Grid>
                );
              })}
              <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.gridSolutionSteps}
              >
                <Grid item>
                  <MathComponent
                    tex={`${calculation.variable} = ${calculation.excercise.solution}`}
                  />
                </Grid>
                <Grid item></Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        ""
      )}

      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth={true}
        onClick={handleButtonClick}
        className={classes.btn}
      >
        {buttonText}
      </Button>
      {tryedToSolve ? (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth={true}
          onClick={handleButtonClickShowSolution}
          className={classes.btn}
        >
          Lösungsweg anzeigen
        </Button>
      ) : (
        ""
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbar.type}
        >
          {snackbar.text}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Equations;
