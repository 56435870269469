import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}));

const StartScreen = ({
  calculationType,
  calculationSelectionChanged,
  difficulty,
  difficultySelectionChanged,
  startClicked,
  handleTimeLimitChange,
  timeLimit,
}) => {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Rechenart auswählen
          </Typography>
          <RadioGroup
            aria-label="Rechenart"
            value={`${calculationType}`}
            onChange={calculationSelectionChanged}
            row={true}
          >
            <FormControlLabel value="0" control={<Radio />} label="+" />
            <FormControlLabel value="1" control={<Radio />} label="-" />
            <FormControlLabel value="2" control={<Radio />} label="X" />
            <FormControlLabel value="3" control={<Radio />} label=":" />
          </RadioGroup>
        </CardContent>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Schwierigkeitsgrad auswählen
          </Typography>
          <RadioGroup
            aria-label="Schwierigkeit"
            value={`${difficulty}`}
            onChange={difficultySelectionChanged}
            row={true}
          >
            <FormControlLabel value="0" control={<Radio />} label="1" />
            <FormControlLabel value="1" control={<Radio />} label="2" />
            <FormControlLabel value="2" control={<Radio />} label="3" />
          </RadioGroup>
        </CardContent>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Zeitlimit
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={timeLimit}
                onChange={handleTimeLimitChange}
                name="timelimit"
                color="primary"
              />
            }
            label={timeLimit ? "An" : "Aus"}
          />
        </CardContent>
      </Card>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth={true}
        onClick={startClicked}
        className={classes.root}
      >
        Start
      </Button>
    </>
  );
};

export default StartScreen;
