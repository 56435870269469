import math from "./mathLib";

class MentalArithmetic {
  calcExercises(count, calculationType, difficulty) {
    var exercises = [];
    let valueFunction = undefined;

    difficulty = parseInt(difficulty);

    switch (parseInt(calculationType)) {
      case 0:
        valueFunction = this.calcValuesAddition;
        break;
      case 1:
        valueFunction = this.calcValuesSubstraction;
        break;
      case 2:
        valueFunction = this.calcValuesMultiplication;
        break;
      case 3:
        valueFunction = this.calcValuesDivision;
        break;
      default:
        return [];
    }

    for (var i = 0; i < count; i++) {
      const [firstValue, secondValue, solution, sign] = valueFunction(
        difficulty
      );

      if (
        exercises.some(
          (excercise) =>
            (excercise.firstValue === firstValue &&
              excercise.secondValue === secondValue) ||
            (excercise.firstValue === secondValue &&
              excercise.secondValue === firstValue)
        )
      ) {
        i--;
        continue;
      }

      exercises.push({
        firstValue: firstValue,
        secondValue: secondValue,
        sign: sign,
        solution: solution,
      });
    }

    return exercises;
  }

  calcValuesAddition(difficulty) {
    let firstValue =
      difficulty === 0
        ? math.random(1, 100)
        : difficulty === 1
        ? math.random(1, 1000)
        : math.random(1, 1000000);

    let secondValue =
      difficulty === 0
        ? math.random(1, 100)
        : difficulty === 1
        ? math.random(1, 1000)
        : math.random(1, 1000);

    if (math.random(0, 2) === 0) {
      const temp = firstValue;
      firstValue = secondValue;
      secondValue = temp;
    }

    var solution = firstValue + secondValue;

    return [firstValue, secondValue, solution, "+"];
  }

  calcValuesSubstraction(difficulty) {
    let firstValue = 0;
    let secondValue = 0;
    do {
      firstValue =
        difficulty === 0
          ? math.random(1, 100)
          : difficulty === 1
          ? math.random(1, 1000)
          : math.random(1, 1000000);

      secondValue =
        difficulty === 0
          ? math.random(1, 100)
          : difficulty === 1
          ? math.random(1, 1000)
          : math.random(1, 1000);

      if (math.random(0, 2) === 0) {
        const temp = firstValue;
        firstValue = secondValue;
        secondValue = temp;
      }
    } while (difficulty === 0 && firstValue < secondValue);

    var solution = firstValue - secondValue;

    return [firstValue, secondValue, solution, "-"];
  }

  calcValuesMultiplication(difficulty) {
    let firstValue = difficulty >= 1 ? math.random(10, 50) : math.random(2, 10);
    let secondValue =
      difficulty >= 2 ? math.random(10, 30) : math.random(2, 10);

    if (math.random(0, 2) === 0) {
      const temp = firstValue;
      firstValue = secondValue;
      secondValue = temp;
    }

    var solution = firstValue * secondValue;

    return [firstValue, secondValue, solution, "X"];
  }

  calcValuesDivision(difficulty) {
    let firstValue = 0;
    let secondValue = 0;
    do {
      firstValue =
        difficulty >= 1 ? math.random(10, 1000) : math.random(10, 100);
      secondValue = difficulty >= 2 ? math.random(10, 30) : math.random(3, 10);

      if (math.random(0, 2) === 0) {
        const temp = firstValue;
        firstValue = secondValue;
        secondValue = temp;
      }
    } while (firstValue < secondValue || firstValue % secondValue !== 0);

    var solution = firstValue / secondValue;

    return [firstValue, secondValue, solution, ":"];
  }
}

const mentalArithmetic = new MentalArithmetic();
Object.freeze(mentalArithmetic);

export default mentalArithmetic;
