import { Link } from "react-router-dom";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },

  card: {
    minHeight: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Home = ({ changeTitle }) => {
  const classes = useStyles();
  changeTitle();
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} lg={6}>
          <Link to="/Equations" className={classes.link}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h2" align="center">
                  Gleichungen
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <Link to="/MentalArithmetic" className={classes.link}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h2" align="center">
                  Kopfrechnen
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
