import { useState } from "react";

import math from "lib/math/mathLib";

import { MathComponent } from "mathjax-react";
import { Button, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

const Fractions = () => {
  // Type of Calculation
  // 0 = +
  // 1 = -
  // 2 = *
  // 3 = /
  const [typeOfCalculation, setTypeOfCalculation] = useState(0);
  const [calculation, setCalculation] = useState(
    math.calcAnFraction(typeOfCalculation)
  );

  const handleButtonClick = () => {
    setCalculation(math.calcAnFraction(typeOfCalculation));
  };

  // const changeTypeOfCalculation = () => {};

  return (
    <>
      <h1>Brüche</h1>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={typeOfCalculation}
        onChange={setTypeOfCalculation}
      >
        <FormControlLabel value="add" control={<Radio />} label="+" />
        <FormControlLabel value="minus" control={<Radio />} label="-" />
        <FormControlLabel value="multiply" control={<Radio />} label="*" />
        <FormControlLabel value="division" control={<Radio />} label="/" />
      </RadioGroup>
      <MathComponent tex={calculation} />
      <Button variant="contained" color="primary" onClick={handleButtonClick}>
        Neue Rechnung
      </Button>
    </>
  );
};

export default Fractions;
